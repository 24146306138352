import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Artchan from "./pages/Artchan";
import Blank from "./pages/Blank";

import "./App.css";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Artchan" component={Artchan} />
        <Route component={Blank} />
      </Switch>
    </BrowserRouter>
  );
}
