import Card from "../components/Card";
import Button from "../components/Button";
import mainimage from "../images/visionai_labs.webp";

function Home() {
  return (
    <div className="content">
      <header>
        <div className="header">
          <h1 className="title">
            VisionAI Labs
            <br />
            <br />
            <img
              src={mainimage}
              className="homeImage"
              alt="VisionAI Labs Office"
            />
          </h1>
          <h3>
            Welcome to VisionAI Labs, we are a team passionate about advancing
            the frontiers of machine learning. We hope to understand the
            principles that drive artificial intelligence, and believe in the
            power of emergent complexity to change the world. By exploring novel
            ideas, developing cutting edge algorithms, and pushing the limits,
            we aim to create transformative technologies that will redefine the
            way we interact with the world.
          </h3>

          <div className="outline">
            <Button title="Contact Us" href="mailto:ruben@visionailabs.org" />
          </div>
        </div>
      </header>

      <h2 className="title">Featured projects</h2>

      <Card
        href="/artchan"
        title="Artchan AI"
        category="AI Art Generator"
        description="An advanced AI art generator for creating stylised art and stock images, engineered to be easy to use for everyone."
      />
    </div>
  );
}

export default Home;
