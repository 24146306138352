import Button from "../components/Button";
import artchan1 from "../images/artchan_1.webp";

function Artchan() {
  return (
    <div className="content">
      <header>
        <div className="header">
          <div>
            <Button title="←" href="/" sameTab={true} />
            <h1 className="title">Artchan AI</h1>
          </div>

          <div>
            <p className="pill2">Web</p>
          </div>
        </div>
      </header>
      <h2 className="title">Description</h2>
      <p>
        Artchan is an advanced web-based AI art generator for creating stylised
        art and stock images, engineered to be easy to use for everyone.
        <br />
        <br />
        Artchan is an AI-powered image generator focused on democratizing art
        creation. It provides users with a simple and accessible way to create
        high-quality art through text prompts. With a rapidly growing community
        of artists, features to clone prompts, and edit photos, Artchan stands
        out as a unique platform for art enthusiasts and proffesionals in need
        for customized stock images.
        <br />
        <br />
        <a href="https://artchan.ai"> Start using it now.</a>
      </p>
      <h2 className="title">Web App</h2>
      <div>
        <div style={{ width: "100%" }}>
          <img src={artchan1} className="homeImage" alt="Artchan screenshot" />
        </div>
      </div>
    </div>
  );
}

export default Artchan;
